import React, { useState }  from 'react';
import { Title, LinkRef } from "../styles";
import styled from 'styled-components';

const ResearchContainer = styled.div`
  margin: 0 auto;
  font-weight: medium;
  font-size: 15px;
  color: #000;
  line-height: 1.2;
  margin-bottom: 30px;
`;

const PaperContainer = styled.article`
  margin-bottom: 20px;
`;

const LinkContainer = styled.div`
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  margin-top: 10px;
  display: ${props => props.visible ? 'block' : 'none'};
`;

const BibText = styled.pre`
  font-size: 13px;
`;

const Paper = ({ title, authors, conference, abstract, bibtex, pdfUrl, codeLink }) => {
  const [showAbstract, setShowAbstract] = useState(false);
  const [showBibtex, setShowBibtex] = useState(false);

  const toggleSection = (section) => {
    if (section === 'abstract') {
      setShowAbstract(!showAbstract);
      setShowBibtex(false);
    } else {
      setShowBibtex(!showBibtex);
      setShowAbstract(false);
    }
  };

  return (
    <PaperContainer>
      <LinkRef href={pdfUrl} target="_blank" dangerouslySetInnerHTML={{ __html: title }}></LinkRef><br />
      <span dangerouslySetInnerHTML={{ __html: authors }} /><br />
      {conference}
      <LinkContainer>
        <LinkRef onClick={() => toggleSection('abstract')}>Abstract</LinkRef>&#160;〳
        <LinkRef onClick={() => toggleSection('bibtex')}>Bibtex</LinkRef>&#160;〳
        {codeLink && <LinkRef href={codeLink} target="_blank">Code</LinkRef>}
      </LinkContainer>
      <ContentContainer visible={showAbstract}>{abstract}</ContentContainer>
      <ContentContainer visible={showBibtex}>
        <BibText>{bibtex}</BibText>
      </ContentContainer>
    </PaperContainer>
  );
};

const Research = () => {
  const papers = [
    {
      title: "<b>Sharpness-Aware Minimization (SAM) Improves Classification Accuracy of Bacterial Raman Spectral Data Enabling Portable Diagnostics</b>",
      authors: "Kaitlin Zareno*, <b>Jarett Dewbury*</b>, Siamak Sorooshyari, Hossein Mobahi, Loza F. Tadesse",
      conference: "ICLR 5th Workshop on Practical ML for Low Resource Settings, 2024 (Oral)",
      abstract: "We address the critical issue of antimicrobial resistance (AMR), projected to cause 10 million deaths annually by 2050, especially affecting resource-limited regions. Utilizing Raman spectroscopy, a novel diagnostic approach for rapid and portable antibiotic resistance testing, we overcome limitations in current algorithms that struggle with generalization on limited datasets and require complex pre-processing. By employing Sharpness-Aware Minimization (SAM), we enhance model generalization and achieve up to a 10.5% accuracy improvement on individual data splits and a 2.7% increase in average accuracy across all splits compared to the Adam optimizer. These results demonstrate SAM's potential to advance AI-powered Raman spectroscopy tools for effective AMR detection.",
      bibtex: `@inproceedings{
  zareno2024sharpnessaware,
  title={Sharpness-Aware Minimization ({SAM}) Improves Classification Accuracy of Bacterial Raman Spectral Data Enabling Portable Diagnostics},
  author={Kaitlin Zareno and Jarett Dewbury and Siamak Sorooshyari and Hossein Mobahi and Loza F. Tadesse},
  booktitle={5th Workshop on practical ML for limited/low resource settings},
  year={2024},
  url={https://openreview.net/forum?id=k6FDRRRddZ}
}`,
      pdfUrl: "https://openreview.net/pdf?id=k6FDRRRddZ",
      codeLink: "https://github.com/Tadesse-Lab/SAM-Raman-Diagnostics"
    }
  ];

  return (
    <ResearchContainer>
      <section id="research">
        <Title>Research</Title>
        {papers.map((paper, index) => (
          <Paper key={index} {...paper} />
        ))}
      </section>
      <p>* Equal Contribution</p>
    </ResearchContainer>
  );
};

export default Research;